.flow-indicator {
    --flow-indicator-bc: var(--clr-black);
    --flow-indicator-opacity-animation-ms: 0.4s;

    g.indicator-1 > rect:nth-child(1) {
        transition: fill-opacity var(--flow-indicator-opacity-animation-ms);
        fill-opacity: 0;
        fill: var(--flow-indicator-1-fill-color, #262626);
    }

    g.indicator-1 > rect:nth-child(2) {
        transition: stroke-opacity var(--flow-indicator-opacity-animation-ms);
        stroke-opacity: 0;
        stroke: var(--flow-indicator-1-stroke-color, var(--flow-indicator-bc));
    }

    g.indicator-2 > rect:nth-child(1) {
        transition: fill-opacity var(--flow-indicator-opacity-animation-ms);
        fill-opacity: 0;
        fill: var(--flow-indicator-2-fill-color, #262626);
    }

    g.indicator-2 > rect:nth-child(2) {
        transition: stroke-opacity var(--flow-indicator-opacity-animation-ms);
        stroke-opacity: 0;
        stroke: var(--flow-indicator-2-stroke-color, var(--flow-indicator-bc));
    }

    g.indicator-3 > rect:nth-child(1) {
        transition: fill-opacity var(--flow-indicator-opacity-animation-ms);
        fill-opacity: 0;
        fill: var(--flow-indicator-3-fill-color, #262626);
    }

    g.indicator-3 > rect:nth-child(2) {
        transition: stroke-opacity var(--flow-indicator-opacity-animation-ms);
        stroke-opacity: 0;
        stroke: var(--flow-indicator-3-stroke-color, var(--flow-indicator-bc));
    }
}

.indicator-1-left-active {
    g.indicator-1.left > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-1.left > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-1-middle-active {
    g.indicator-1.middle > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-1.middle > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-1-right-active {
    g.indicator-1.right > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-1.right > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-2-left-active {
    g.indicator-2.left > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-2.left > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-2-middle-active {
    g.indicator-2.middle > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-2.middle > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-2-right-active {
    g.indicator-2.right > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-2.right > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-3-left-active {
    g.indicator-3.left > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-3.left > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-3-middle-active {
    g.indicator-3.middle > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-3.middle > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}

.indicator-3-right-active {
    g.indicator-3.right > rect:nth-child(1) {
        fill-opacity: 1;
    }

    g.indicator-3.right > rect:nth-child(2) {
        stroke-opacity: 1;
    }
}
