/* You can add global styles to this file, and also import other style files */
@import 'generic.font';
@import 'settings.fonstack';
@import 'node_modules/@eon-is/eon-is-reset-css/dist/eon-is-reset-css.css';
@import 'node_modules/@eon-is/eon-is-visually-hidden/dist/eon-is-visually-hidden.css';
@import 'src/app/directive/animate-flow-indicator/flow-indicator';

:root {
    --clr-yellow: #edea6c;
    --clr-blue: #7fc7cf;
    --clr-red: #f59a86;
    --clr-eon-red: rgb(235 26 10);
    --clr-black: #262626;
    --clr-grey: #cccbca;
    --clr-background-dark: #e6e3e1;
    --clr-background-normal: #f9f6f4;
    --clr-background-bright: #fff;
    --clr-border: var(--clr-black);
    --ff-body: $eon-brix-fontstack;
    --ff-accent: $eon-brix-fontstack;
    --fw-regular: 400;
    --fw-semi-bold: 700;
    --fw-bold: 900;
    --fs-smallest: 0.62rem;
    --fs-small: 0.75rem;
    --fs-normal: 1rem;
    --fs-bigger: 1.875rem;
    --fs-biggest: 2.5rem;
    --opacity-light: 0.9;
    --border-radius: 18px;
}

body {
    font-family: $eon-brix-fontstack;
    font-weight: var(--fw-regular);
    font-size: var(--fs-normal);
    scroll-behavior: smooth;
    text-rendering: optimizespeed;
}

h2 {
    font-size: var(--fs-biggest);
    font-weight: var(--fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--clr-black);
}

h3 {
    font-size: var(--fs-normal);
    font-weight: var(--fw-bold);
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    text-align: left;
    color: var(--clr-black);
}

.card {
    padding: 2rem;
    border-radius: var(--border-radius);
    border: solid 4px rgb(0 0 0 / 4%);
    background-color: var(--clr-background-bright);
}

.colspan-2 {
    grid-column: span 2;
}

.colspan-3 {
    grid-column: span 3;
}

.colspan-4 {
    grid-column: span 4;
}

.colspan-5 {
    grid-column: span 5;
}

.spacer {
    flex-grow: 1;
}

section.gauges-container {
    img.icon[src='/assets/img/icon/building.svg'],
    img.icon[src='/assets/img/icon/power-pole.svg'] {
        width: 2rem;
    }
}

// workaround for hiding mobile burger menu
.burger-menu-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 64px;
    height: 56px;
    z-index: 500;
    background-color: var(--clr-background-bright);
}
